<script setup lang="ts">
import type { AuthAuthGetCipherRouteRpc, AuthAuthLoginByEmailRouteRpc } from '@repo/contract';
import { navigateTo, onMounted, ref, watch } from '#imports';
import { useLoginValidation } from '~/pages/login/composables/use-login-validation';
import BaseButton from '~/shared/components/buttons/base-button.vue';
import BaseClickableText from '~/shared/components/buttons/base-clickable-text.vue';
import BaseFormGroup from '~/shared/components/form/base-form-group.vue';
import BaseInput from '~/shared/components/form/base-input.vue';
import { encryptRsa } from '~/utils/encrypt-rsa';

const data = ref({
  email: '',
  password: '',
});

const isLoginButtonDisabled = ref(false);

const { hasErrors, trigger } = useLoginValidation(data);

onMounted(() => {
  trigger();

  watch(hasErrors, () => {
    isLoginButtonDisabled.value = hasErrors.value;
  }, { immediate: true });
});

async function onSubmit() {
  if (hasErrors.value) return;

  const {
    publicKey,
    cipherId,
  } = await $fetch<typeof AuthAuthGetCipherRouteRpc.response>('/api/rpc/auth/auth/get-cipher');

  console.log('publicKey', publicKey, 'cipherId', cipherId);

  // const [email, password] = await Promise.all([
  //   encryptRsa(data.value.email, publicKey),
  //   encryptRsa(data.value.password, publicKey),
  // ]);

  const params = {
    email: data.value.email,
    password: data.value.password,
    cipherId,
  } as typeof AuthAuthLoginByEmailRouteRpc.request;

  const response = await $fetch('/api/rpc/auth/auth/login-by-email', {
    method: 'POST',
    body: params,
  });

  console.log(response);

  navigateTo('/');
}
</script>

<template>
  <form class="flex flex-col w-full items-center gap-29.5" @submit.prevent="onSubmit">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-4 w-80">
        <BaseFormGroup name="email" error="" label="Email">
          <BaseInput
            id="email" v-model="data.email" name="email" placeholder="Enter Email"
          />
        </BaseFormGroup>

        <BaseFormGroup name="password" error-disabled label="Password">
          <BaseInput
            id="password" v-model="data.password" name="password" type="password" placeholder="Enter Password"
          />
        </BaseFormGroup>
      </div>

      <div class="flex justify-end">
        <BaseClickableText to="/forgot-password" text="Forgot Password?" />
      </div>
    </div>

    <div class="flex flex-col gap-10 items-center">
      <BaseButton
        variant="primary"
        type="submit"
        :disabled="isLoginButtonDisabled"
      >
        Login
      </BaseButton>

      <p>Don't have an account? <BaseClickableText to="/register" text="Register here" /></p>
    </div>
  </form>
</template>
